import React from 'react';
import Layout from '../../components/Layout';
import CostSupport, {
  costAndSupportBody
} from '../../components/CostSupport/CostSupport';
import Hero2, { costAndSupport } from '../../components/Hero/Hero2';
import TwoColor, {
  costAndSupportTwoColor
} from '../../components/TwoColor/TwoColor';
import GlobalCostAndSavings from '../../components/GlobalCostAndSavings/global-cost-and-savings';

const Home = () => {
  return (
    <Layout section="UF" page="/uterine-fibroids/cost-and-support/">
      <>
        <GlobalCostAndSavings />

        {/* <Hero2 {...costAndSupport} />
        <TwoColor {...costAndSupportTwoColor} />
        {costAndSupportBody.map((costAndSupportProps, index) => (
          <CostSupport {...costAndSupportProps} key={index} />
        ))} */}
      </>
    </Layout>
  );
};

export const Head = () => {
  const canonicalURL =
    'https://www.myfembree.com/uterine-fibroids/cost-and-support/';
  const title =
    'Cost & Savings | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) 40mg, 1mg, 0.5mg Tablets';
  const pageTitle =
    'Cost & Savings | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) 40mg, 1mg, 0.5mg Tablets';
  const description =
    'Discover potential cost-saving solutions and co-pay card details for MYFEMBREE® treatment of heavy menstrual bleeding related to uterine fibroids. See safety info & BOXED WARNING.';
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
    </>
  );
};

export default Home;
